import { useState, useEffect } from 'react';

export function useToggle(init) {
	const [ value, setValue ] = useState(init);

	return [ value, () => { setValue(prev => !prev); } ];
}

export function useLoading(apiFunc) {
	const [ isInProgress, setIsInProgress ] = useState(false);

	const callApi = (...params) => {
		setIsInProgress(true);
		apiFunc(...params)
			.finally(() => { setIsInProgress(false); });
	};

	return [ callApi, isInProgress ];
}

export const useScreenSize = () => {
	const [ screenSize, setScreenSize ] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};
