import React, { useEffect, useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { GET_DOCUMENTS_LIST } from '../../services/api-calls';
import { DOWNLOAD_DOCUMENT } from 'common-frontend/services/api-calls';
import { useStores } from 'common-frontend/components/store-provider';
import RefreshIcon from '@mui/icons-material/Refresh';
import { InboundDocumentsTable } from '../components/tables';

const PAGE_SIZE = 10;

export const DocumentsPage = observer(() => {
	const { t } = useTranslation();
	const { DocumentsStore, GuidesStore, UserStore } = useStores();
	const { user } = UserStore;
	const { documents, pagesCount, currentPage, setCurrentPage } = DocumentsStore;
	const { guideStepUpdate } = GuidesStore;

	const [ docsLoader, setDocsLoader ] = React.useState(false);

	useEffect(() => {
		getDocumentsHandler();
	}, []);

	const getDocumentsFinally = () => {
		setDocsLoader(false);
	};

	const getDocumentsHandler = useCallback(() => {
		setDocsLoader(true);
		setCurrentPage(1);
		GET_DOCUMENTS_LIST(1, PAGE_SIZE, 'inbound').finally(() => {
			getDocumentsFinally();
			guideStepUpdate();
		});
	}, []);

	const downloadDocument = useCallback((doc) => {
		DOWNLOAD_DOCUMENT(doc).finally(() => {
			sessionStorage.setItem('document_downloaded', 'true');
			guideStepUpdate();
		});
	}, []);

	const handlePageChange = useCallback((e, newPage) => {
		setDocsLoader(true);
		setCurrentPage(newPage);
		GET_DOCUMENTS_LIST(newPage, PAGE_SIZE, 'inbound').finally(getDocumentsFinally);
	}, []);

	return (
		<div className="documents-page">
			<Grid container columnSpacing={6} rowSpacing={6}>
				<Grid item sm={12} xs={12}>
					<Typography variant="h2" className="mt-32">
						{ t('documents.content.title') }
					</Typography>
					<Typography className="mt-16">
						<b>{ t('documents.content.subtitle') }</b>
					</Typography>
				</Grid>
				<Grid item sm={6} xs={12}>
					<Button id="refresh-documents-button" variant="contained" disabled={ docsLoader || !user } onClick={ getDocumentsHandler }>
						<RefreshIcon />
					</Button>
				</Grid>
				<Grid item sm={12} xs={12}>
					<InboundDocumentsTable
						documents={documents}
						loading={docsLoader || !user}
						pagesCount={pagesCount}
						currentPage={currentPage}
						downloadDocument={downloadDocument}
						handlePageChange={handlePageChange}
						t={t}
					/>
				</Grid>
			</Grid>
		</div>
	);
});