import logo from '../assets/images/logo.png';
import header from '../assets/images/header-background.jpg';
import school from '../assets/images/school.jpg';
import qr from '../assets/images/qr.jpg';
import favicon from '../assets/images/school-fav.png';

export const IMAGES = {
	logo,
	header,
	school,
	qr,
	favicon
};
