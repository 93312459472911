import React, { useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { versionsFormater } from 'common-frontend/utils/formatters';

export const Footer = observer(() => {
	const { t } = useTranslation();
	const { AuthStore, UserStore } = useStores();
	const { authProfile } = AuthStore;
	const { user, userStatusCode } = UserStore;

	const [ localLoader, setLocalLoader ] = useState(false);
	const [ removeUserPopup, setRemoveUserPopup ] = useState(false);

	const removeRelationship = useCallback(() => {
		setLocalLoader(true);
		setRemoveUserPopup(false);

		REMOVE_RELATIONSHIP().finally(() => {
			setLocalLoader(false);
		});
	}, []);

	return (
		<footer>
			<div className="footer-content">
				<div>
					<Typography><b>{ t('home.footer.title') }</b></Typography>
					<Typography>{ t('home.footer.street') }</Typography>
					<Typography>{ t('home.footer.city') }</Typography>
					{((authProfile && userStatusCode === '403') || user) &&
						<>
							<Button id="remove-user-button" className="mt-8" disabled={localLoader} variant="contained" onClick={() => { setRemoveUserPopup(true); }} >{t('remove-user.button')}</Button>
							<AcceptPopup id='remove-user' title={t('remove-user.title')} text={t('remove-user.dialog')}
								isOpen={ removeUserPopup } isDanger={ true }
								onClose={ () => { setRemoveUserPopup(false); }}
								action={ removeRelationship } />
						</>
					}
				</div>
				<div>
					<Typography><Trans i18nKey="home.footer.phone" /></Typography>
					<Typography><Trans i18nKey="home.footer.email" /></Typography>
				</div>
			</div>
			<div className="versions-container">
				<VersionsMemo />
			</div>
		</footer>
	);
});

const VersionsMemo = React.memo(() => <Typography variant="caption">{ versionsFormater() }</Typography>);
