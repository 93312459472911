import React, { useState } from 'react';
import { Pagination, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SendIcon from '@mui/icons-material/Send';
import { SIGN_DOCUMENT, VERIFY_DOCUMENT, REVOKE_DOCUMENT, SEND_DOCUMENT } from '../../services/api-calls';
import { dateFormatter } from 'common-frontend/utils/formatters';
import { useScreenSize, useLoading, useToggle } from 'common-frontend/utils/hooks';
import { AcceptPopup } from 'common-frontend/components/accept-popup';

export const InboundDocumentsTable = ({ documents, loading, pagesCount, currentPage, downloadDocument, handlePageChange, t }) => {
	return (
		<>
			<TableContainer>
				<Table className="table">
					<TableHead>
						<TableRow>
							<TableCell>{t('documents.table.filename')}</TableCell>
							<TableCell width="20%">{t('documents.table.dna-status')}</TableCell>
							<TableCell width="20%">{t('documents.table.created-at')}</TableCell>
							<TableCell align="center" width="15%">{t('documents.table.download')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<BodyWrapper documents={documents} loading={loading} columnsCount={4} t={t}>
							{documents.map((doc, i) => (
								<TableRow
									key={doc.uuid}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>{doc.title || doc.filename}</TableCell>
									<TableCell>{doc.dnaStatus ? t(`documents.dna-statuses.${ doc.dnaStatus }`) : ''}</TableCell>
									<TableCell>{dateFormatter(doc.createdAt)}</TableCell>
									<TableCell align="center">
										<Link to='#download' id={ `download-documents-button-${ i + 1 }` } onClick={ () => downloadDocument(doc) } title={ t('documents.table.download') }>
											<FileDownloadIcon/>
										</Link>
									</TableCell>
								</TableRow>
							))}
						</BodyWrapper>
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination count={ pagesCount } page={ currentPage } color="primary" onChange={ handlePageChange } />
		</>
	);
};

export const OutboundDocumentsTable = ({ documents, loading, pagesCount, currentPage, downloadDocument, handlePageChange, t }) => {
	return (
		<div className="outbound-area-container">
			<TableContainer>
				<Table className="table outbound">
					<TableHead>
						<TableRow>
							<TableCell>{t('documents.table.filename')}</TableCell>
							<TableCell width="12.5%">{t('documents.table.dna-status')}</TableCell>
							<TableCell width="12.5%">{t('documents.table.verification-status')}</TableCell>
							<TableCell width="12.5%">{t('documents.table.upload-date')}</TableCell>
							<TableCell width="12.5%">{t('documents.table.last-sent-date')}</TableCell>
							<TableCell width="10%" align="center">{t('documents.table.download')}</TableCell>
							<TableCell width="7.5%" align="center">{t('documents.table.actions')}</TableCell>
							<TableCell width="10%" align="center">{t('documents.table.send-to-wallet')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<BodyWrapper documents={documents} loading={loading} columnsCount={7} t={t}>
							{documents.map((doc, i) =>
								<OutboundDocumentsRow
									key={doc.uuid}
									doc={doc}
									i={i}
									t={t}
									downloadDocument={downloadDocument}
								/>
							)}
						</BodyWrapper>
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination count={ pagesCount } page={ currentPage } color="primary" onChange={ handlePageChange } />
		</div>
	);
};

const OutboundDocumentsRow = ({ doc: docParam, i, t, downloadDocument }) => {
	const [ doc, setDoc ] = useState(docParam);
	const [ revokePopup, toggleRevokePopup ] = useToggle(false);
	const [ verifyDocument, verifying ] = useLoading(VERIFY_DOCUMENT);
	const [ signDocument, signing ] = useLoading(SIGN_DOCUMENT);
	const [ revokeDocument, revoking ] = useLoading(REVOKE_DOCUMENT);
	const [ sendDocument, sending ] = useLoading(SEND_DOCUMENT);
	const { width: screenWidth } = useScreenSize();

	const dnaStatus = doc.dnaStatus ? t(`documents.dna-statuses.${doc.dnaStatus}`) : '';
	const verificationStatus = doc.verificationStatus ? t(`documents.verification-statuses.${doc.verificationStatus}`) : '';
	const isSent = !!doc.lastSentDate;
	const sendToWalletButtonText = t(isSent ? 'documents.table.sent-button' : 'documents.table.send-to-wallet-button');

	const getActionComponent = () => {
		if (doc.status === 'UNMANAGEABLE') {
			return null;
		}

		if (doc.dnaStatus !== 'SIGNED') {
			return (
				<LoadingButton fullWidth id={`sign-button-${i + 1}`} loading={signing} onClick={() => { signDocument(doc.uuid, setDoc); }}>
					{t('documents.table.sign-button')}
				</LoadingButton>
			);
		}

		if (doc.verificationStatus !== 'VERIFIED') {
			return (
				<LoadingButton fullWidth id={`verify-button-${i + 1}`} loading={verifying} onClick={() => { verifyDocument(doc.uuid, setDoc); }}>
					{t('documents.table.verify-button')}
				</LoadingButton>
			);
		}

		return (
			<>
				<LoadingButton fullWidth id={`revoke-button-${i + 1}`} loading={revoking} onClick={toggleRevokePopup}>
					{t('documents.table.revoke-button')}
				</LoadingButton>
				<AcceptPopup
					id='revoke-document'
					title={t('revoke-document.title')}
					text={t('revoke-document.dialog')}
					isOpen={revokePopup}
					isDanger
					onClose={toggleRevokePopup}
					action={() => {
						revokeDocument(doc.uuid, setDoc);
						toggleRevokePopup();
					}}
					closeIcon
				/>
			</>
		);
	};

	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{doc.title || doc.filename}</TableCell>
			{(signing || revoking) ? <CellSkeleton /> : <TableCell>{dnaStatus}</TableCell>}
			{(signing || verifying || revoking) ? <CellSkeleton /> : <TableCell>{verificationStatus}</TableCell>}
			<TableCell>{dateFormatter(doc.uploadDate)}</TableCell>
			{(revoking && doc.lastSentDate) ? <CellSkeleton /> : <TableCell>{dateFormatter(doc.lastSentDate)}</TableCell>}
			<TableCell align="center">
				<Link to='#download' id={`download-documents-button-${i + 1}`} onClick={() => downloadDocument(doc)} title={t('documents.table.download')}>
					<FileDownloadIcon/>
				</Link>
			</TableCell>
			<TableCell align="center">
				{getActionComponent()}
			</TableCell>
			{(revoking && isSent)
				? <CellSkeleton />
				: (
					<TableCell align="center">

						<LoadingButton fullWidth id={`send-to-wallet-button-${i + 1}`} loading={sending} disabled={isSent} onClick={() => { sendDocument(doc.uuid, setDoc); }}>
							{screenWidth > 1100 ? sendToWalletButtonText : <SendIcon />}
						</LoadingButton>
					</TableCell>
				)
			}
		</TableRow>
	);
};

const BodyWrapper = ({ children, documents, loading, columnsCount, t }) => {
	const bodyLayout = documents.length === 0
		? <TableRow><TableCell align="center" colSpan={columnsCount}>{t('documents.empty')}</TableCell></TableRow>
		: children;

	return loading ? <RowsSkeleton columnsCount={columnsCount} /> : bodyLayout;
};

const RowsSkeleton = ({ columnsCount }) => {
	function* generateNumbers(n) {
		for (let i = 1; i <= n; i++) {
			yield i;
		}
	}

	return [ ...generateNumbers(5) ].map((i) => (
		<TableRow key={i}>
			{[ ...generateNumbers(columnsCount) ].map((j) => <CellSkeleton key={j} />)}
		</TableRow>
	));
};

const CellSkeleton = () => (
	<TableCell component="td" scope="row">
		<Skeleton animation="wave" variant="text" />
	</TableCell>
);