import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { THEME } from './constants/theme';
import { IMAGES } from './constants/images';

import { Header } from './layouts/components/header';
import { Footer } from './layouts/components/footer';
import { Guides } from './layouts/components/guides';
import { Router } from './layouts/components/router';

import { STORES } from './services/stores';

import { APP_SUFFIX } from 'common-frontend/constants/env';
import { StoresProvider } from 'common-frontend/components/store-provider';
import { Loader } from 'common-frontend/components/loader';
import { Auth } from 'common-frontend/components/auth';

export const App = () => {
	return (
		<StoresProvider stores={STORES}>
			<ThemeProvider theme={THEME}>
				<SnackbarProvider>
					<BrowserRouter basename={APP_SUFFIX}>
						<Helmet>
							<link rel="icon" href={IMAGES.favicon}/>
						</Helmet>
						<Header/>
						<Router/>
						<Footer/>
						<Auth />
						<Loader/>
						<Guides/>
					</BrowserRouter>
				</SnackbarProvider>
			</ThemeProvider>
		</StoresProvider>
	);
};