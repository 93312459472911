import React, { useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { IMAGES } from '../../constants/images';
import { useTranslation } from 'react-i18next';
import { SYNC_LANGUAGE } from 'common-frontend/services/api-calls';
import { PATHS } from '../../constants/paths';
import { UserBar } from 'common-frontend/components/user-bar';

export const Header = observer(() => {
	const { t, i18n } = useTranslation();
	const { UserStore } = useStores();
	const { user } = UserStore;

	const switchLanguage = useCallback((lang) => {
		i18n.changeLanguage(lang); // en || de
		user && SYNC_LANGUAGE();
	}, [ user, i18n ]);

	return (
		<>
			<header>
				<UserBar />
				<div className="branding">
					<img src={IMAGES.header} className="branding__background" alt="header" />
					<img src={IMAGES.logo} className="branding__logo" alt="logo" />
					<Typography className="branding__text-primary" variant="h1">{ t('home.header.title') }</Typography>
					<Typography className="branding__text-secondary" variant="h4">{ t('home.header.subtitle') }</Typography>
				</div>
				<div className="nav-container">
					<nav className="nav">
						<NavLink id="to-news-link" to={ PATHS.home }>{ t('nav.news') }</NavLink>
						<NavLink id="to-school-activities-link" to={ PATHS.portal } className={ !user ? 'disabled' : '' }>{ t('nav.activities') }</NavLink>
						<NavLink id="to-documents-link" to={ PATHS.documents } className={ !user ? 'disabled' : '' }>{ t('nav.documents') }</NavLink>
						<div className="language-switch-container">
							<Link className="language-switch" translate="no" onClick={() => { switchLanguage('de'); }}>DE</Link>
							<Link className="language-switch" translate="no" onClick={() => { switchLanguage('en'); }}>EN</Link>
						</div>
						<NavLink id="to-user-link" to={ PATHS.user } className={ !user ? 'disabled' : '' }>{ t('nav.user') }</NavLink>
					</nav>
				</div>
			</header>
		</>
	);
});


