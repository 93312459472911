import { createTheme } from '@mui/material';

export const THEME = createTheme({
	palette: {
		primary: {
			light: '#004b76',
			main: '#004b76',
			dark: '#739CB4',
			text: '#fff',
		},
		secondary: {
			light: '#fff',
			main: '#f8f8f8',
			dark: '#dfdfdf',
			text: '#222',
		},
	},
	typography: {
		fontFamily: 'Source Sans Pro',
		h1: {
			fontSize: '40px',
			textAlign: 'center',
			color: '#222',
		},
		h2: {
			fontSize: '36px',
		},
		h3: {
			fontSize: '24px',
			fontWeight: '700',
		},
		h4: {
			fontSize: '16px'
		}
	},
	variables: {
		maxWidth: '1100px'
	},
});
