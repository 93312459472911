import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from '../pages/home';
import { ErrorPage } from 'common-frontend/components/error';
import { ActivitiesPage } from '../pages/activities';
import { DocumentsPage } from '../pages/documents';
import { PATHS } from '../../constants/paths';
import { UserPage } from '../pages/user';

export const Router = ({ getQrHandler }) => {
	return (
		<Routes>
			<Route path={ PATHS.home } element={ <HomePage getQrHandler={getQrHandler} /> } errorElement={ <ErrorPage/> }/>
			<Route path={ PATHS.portal } element={ <ActivitiesPage/> } errorElement={ <ErrorPage/> }/>
			<Route path={ PATHS.documents } element={ <DocumentsPage/> } errorElement={ <ErrorPage/> }/>
			<Route path={ PATHS.user } element={ <UserPage/> } errorElement={ <ErrorPage/> }/>
			<Route path="*" element={ <ErrorPage message={'404 page not found'}/> } />
		</Routes>
	);
};
